import React, { useEffect, useState } from 'react'
import { deleteImagesEndPoint, fetchAllImages } from '../../../api/api_endpoint';
import { const_data } from '../../../const/const_data';
import { useImageReloadContext } from '../../../Context/ImageReloadContext';
import { useImageModelContext } from '../../../Context/ImageModelContext';
import { toast } from 'react-toastify';

function SelectImage({ onSelect, currentlySelectedImg = [] }) {

    let [images, imagesUpdate] = useState([]);
    let { reloadFlag, setReloadFlag } = useImageReloadContext()
    const [selectedImages, setSelectedImage] = useState(
        currentlySelectedImg.length > 0 ? currentlySelectedImg?.toString()?.split(',') : []
    );

    

    function addImageToSelection(imageUrl) {
        setSelectedImage((prevSelectedImages) => {
            if (prevSelectedImages.includes(imageUrl)) {
                return prevSelectedImages.filter((each) => each !== imageUrl);
            }
            else {
                return [...prevSelectedImages, imageUrl];
            }
        });
    }

    let { flatImageModel, setflatImageModel } = useImageModelContext();

    useEffect(() => {
        fetchAllImages().then((data) => {
            if (data?.data?.status) {
                let images = data?.data?.data;
                imagesUpdate(images)
            }
        }).catch((err) => {
            toast.success("Something went wrong for image fetching", const_data.DEFAULT_ALERT_DATA)
        })
    }, [reloadFlag])


    function onImageDrag(e) {
        let imageName = e.currentTarget.getAttribute('data-imagename');
        setflatImageModel({ ...flatImageModel, isShow: true, src: const_data.public_image_url + "/" + imageName })
    }
    function deleteImages() {

        let confirm = window.confirm("Are you sure want to delete image");
        console.log(selectedImages);
        if (confirm) {
            if (selectedImages.length) {
                deleteImagesEndPoint(selectedImages).then((data) => {
                    console.log(data)
                    setReloadFlag(!reloadFlag)
                    toast.success("Image delete success", const_data.DEFAULT_ALERT_DATA)
                }).catch((err) => {
                    setReloadFlag(!reloadFlag)
                    toast.error("Image delete failed", const_data.DEFAULT_ALERT_DATA)
                })
            }
        }
    }

    return (
        <div className="content_show_image_filespopup">
            <div className="row h100">
                <div className="col-12">
                    <div className="image_list_show">

                        <div className="imageuploadlist">
                            <ul className="mt-3 row">
                                {
                                    images?.length > 0 && (
                                        images.map((item, index) => (
                                            <div onClick={() => addImageToSelection(item?.image)} className="col-md-4 col-6" key={item.image}>
                                                <li
                                                    onDrag={onImageDrag}
                                                    className={` ${selectedImages.includes(item?.image) ? 'selectedimage' : ''}`}
                                                >
                                                    <img src={item.image} className="viewImages w-100" alt="" />
                                                </li>
                                            </div>


                                        )
                                        )
                                    )
                                }
                            </ul>
                        </div>
                    </div>
                    <div className="button_group">
                        <div className={`flex_avoid button_flex ${selectedImages.length === 0 ? 'button_disabled' : ''}`}>

                            <div onClick={deleteImages}>
                                <button className="btn btn-danger" disabled={selectedImages.length === 0 ? true : false} type="button">
                                    <i className="fa fa-trash"></i> Delete
                                </button>
                            </div>

                            <div>
                                <button type="button" onClick={() => onSelect(selectedImages)} className="btn btn-success" disabled={selectedImages.length === 0 ? true : false} >
                                    Done
                                </button>
                            </div>
                        </div>
                    </div>
                </div>

            </div >
        </div >
    )
}

export default SelectImage
