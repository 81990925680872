import React, { useEffect, useRef, useState } from 'react'
import HeadWindow from './Component/HeadWindow'
import SelectImage from './Component/SelectImage'
import UploadTab from './Component/UploadTab'
import ImageReloadContext from '../../Context/ImageReloadContext'

function UploadImage({ onImageSelect, currentlySelectedImg = [] }) {
    const [isOpen, setToggle] = useState(false)
    const model = useRef(null)
    const popModel = useRef(null)
    const [selectedTab, setTab] = useState(1)
    const [selectedImages, setSelectedImage] = useState(currentlySelectedImg)
    
    
    useEffect(() => {
        const handleClickOutside = (e) => {
            if (popModel.current && !popModel.current.contains(e.target)) {
                setToggle(false)
            }
        }

        if (isOpen) {
            document.addEventListener('click', handleClickOutside)
        }

        return () => {
            document.removeEventListener('click', handleClickOutside)
        }
    }, [isOpen])

    const handleSelectImages = (images) => {
        setSelectedImage(images);
        onImageSelect(images);
        setToggle(false);
      };

    return (
        <ImageReloadContext>
            <div onClick={(e) => {
                e.stopPropagation();
                setToggle(true)
            }}>
                <div className="form_group">
                    <input
                        value={selectedImages.join(",")}
                        name="images"
                        type="text"
                        placeholder="Select Images"
                        style={{ pointerEvents: "none" }}
                    />
                </div>
            </div>
            {isOpen && (
                <div ref={model} className="image_upload_popup">
                    <div className="flex_avodid_pop" ref={popModel}>
                        <div className="image_upload_wrapper">
                            <div className="top_tab_head">
                                <h4>Upload/Select Image</h4>
                                <i onClick={() => setToggle(false)} className="close_image_modal" id="close_modal_popup_image" type="button">X</i>
                                <ul className="tab_upload_files">
                                    <li>
                                        <button onClick={() => setTab(0)} className={`${selectedTab == 0 && `active_media_tab`} upload_media_tab_btn tab_file_upload`} data-widget="upload_image_tab" type="button">Upload   Files</button>
                                        <button onClick={() => setTab(1)} className={`${selectedTab == 1 && `active_media_tab`} tab_file_upload mediatabbtn`} data-widget="showimagedata" type="button">File manager</button>
                                    </li>
                                </ul>
                            </div>
                            <div className="tab_items_image_upload">
                                {selectedTab == 0 ? <UploadTab /> : <SelectImage onSelect={handleSelectImages} currentlySelectedImg={selectedImages} />}

                            </div>
                        </div>
                    </div>
                </div>
            )}
        </ImageReloadContext>
    )
}

export default UploadImage
