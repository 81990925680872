import React from 'react'

function InfoBox({ children }) {
    return (
        <div className="box_data_quanity">
            {
                children
            }
        </div>
    )
}

export default InfoBox
