import React, { useEffect, useRef, useState } from 'react'
import AdminLayout from '../../Components/Partials/AdminLayout'
import { Formik, Form, Field, ErrorMessage } from 'formik';
import * as Yup from 'yup';
import { addProduct, getAllCategoryEndPoint, getSingleProduct, updateProduct } from '../../api/api_endpoint';
import { toast } from 'react-toastify';
import { const_data } from '../../const/const_data';
import { useNavigate, useParams } from 'react-router-dom';
import UploadImage from '../../Components/ImageModel/UploadImage';
function EditProduct() {


  // let productImags = useRef();
  let [productImages,setProductImages] = useState([])
  
  let [productImags,setProductImage] = useState()
  let [productImagesError, updateImageError] = useState({ error: false, msg: "Product images is required" })
  let { edit_id } = useParams();
  let navigate = useNavigate();
  let [startEditing, setStartEditing] = useState(false);
  let [initialValues, setInitialValues] = useState({
    name: '',
    small_description: '',
    sale_price: 0,
    original_price: 0,
    category: '',
    description: '',
    stock: 0,
    status: true,
  })
  let [productCategory, setProductCategory] = useState([]);


  useEffect(() => {
    getAllCategoryEndPoint().then((response) => {
      let categoryData = response.data;
      if (categoryData.status) {
        let category = categoryData.categorys;
        setProductCategory(category)
      }
    }).catch((err) => {

    })
  }, [])

  useEffect(() => {
    getSingleProduct(edit_id).then((product_data) => {

      console.log(product_data)
      let response = product_data.data;
      if (response.status) {
        let product_item = response.product;
        setInitialValues({
          name: product_item.name,
          small_description: product_item.small_description,
          sale_price: product_item.sale_price,
          original_price: product_item.original_price,
          category: product_item.category?._id,
          description: product_item.description,
          stock: product_item.stock,
          status: product_item.status,
        })
        let productImages = product_item.images?.join(",");
        
        setStartEditing(true)
        setProductImage(productImages)
        setProductImages(product_item.images)
      

      } else {
        navigate("/manage_product")
      }
    }).catch((err) => {
      console.log(err)
      // navigate("/manage_product")
    })
  }, [edit_id])

  const validationSchema = Yup.object().shape({
    name: Yup.string().trim().required('Name is required'),
    small_description: Yup.string().trim().required('Small description is required'),
    sale_price: Yup.number()
      .lessThan(Yup.ref("original_price"), "Sales price must be less than the original price")
      .min(1, "Sales price should be a minimum of 1")
      .required('Sale price is required'),
    original_price: Yup.number()
      .moreThan(Yup.ref("sale_price"), "Original price must be greater than the sale price")
      .min(1, "Original price should be a minimum of 1")
      .required('Original price is required'),
    category: Yup.string().trim().required('Category is required'),
    description: Yup.string().trim().required('Description is required'),
    stock: Yup.number().min(0).required('Stock is required'),
    status: Yup.string().trim().required("Select Product Status")
  });

  const handleSubmit = (values) => {

    if (startEditing) {
      let images = productImags;

      if (images == "" || images == null) {
        updateImageError({ error: true, msg: "Product images is required" });
        return false;
      } else {

        let productData = {
          name: values.name,
          small_description: values.small_description,
          sale_price: values.sale_price,
          original_price: values.original_price,
          category: values.category,
          description: values.description,
          stock: values.stock,
          images: images?.toString().split(','),
          status: values.status
        }

        updateProduct(edit_id, productData).then((data) => {

          try {
            if (data?.data?.status) {
              toast.success("Product update success", const_data.DEFAULT_ALERT_DATA)
              navigate("/manage_product")
            } else {
              toast.error(data?.data?.msg, const_data.DEFAULT_ALERT_DATA)
            }
          } catch (e) {
            toast.error("Something went wrong", const_data.DEFAULT_ALERT_DATA)
          }
        }).catch((err) => {
          toast.error("Something went wrong", const_data.DEFAULT_ALERT_DATA)
        })

      }
    }
  };


  return (
    <AdminLayout>


      <div className="content_body" id="content_body">
        <div className="wrapper_content_body">
          <h2>Edit Product</h2>
          {
            startEditing ? <div className="content_box_border">
              <div className="row mt-2">
                <Formik
                  initialValues={initialValues}
                  validationSchema={validationSchema}
                  onSubmit={handleSubmit}
                  validate={(values) => {
                    let error = {};
                    if (!productImages || productImages.length === 0) {
                        error.productImage = "Select product images"
                    } else {
                        error = {};
                    }
                    return error;
                }}
                  enableReinitialize
                >
                  <Form>
                    <div className="row">
                      <div className="col-md-6">
                        <div className="form_group">
                          <label htmlFor="name">Name</label>
                          <Field id="name" name="name" type="text" placeholder="Product Name" />
                          <ErrorMessage name="name" component="div" className="formValidateError" />
                        </div>
                      </div>

                      <div className="col-md-6">
                        <div className="form_group">
                          <label htmlFor="small_description">Small Description</label>
                          <Field id="small_description" name="small_description" type="text" placeholder="Small Description" />
                          <ErrorMessage name="small_description" component="div" className="formValidateError" />
                        </div>
                      </div>

                      <div className="col-md-6">
                        <div className="form_group">
                          <label htmlFor="description">Description</label>
                          <Field id="description" name="description" type="text" placeholder="Description" />
                          <ErrorMessage name="description" component="div" className="formValidateError" />
                        </div>
                      </div>

                      <div className="col-md-6">
                        <div className="form_group">
                          <label htmlFor="sale_price">Sale Price</label>
                          <Field id="sale_price" name="sale_price" type="number" placeholder="Sale Price" />
                          <ErrorMessage name="sale_price" component="div" className="formValidateError" />
                        </div>
                      </div>

                      <div className="col-md-6">
                        <div className="form_group">
                          <label htmlFor="original_price">Original Price</label>
                          <Field id="original_price" name="original_price" type="number" placeholder="Original Price" />
                          <ErrorMessage name="original_price" component="div" className="formValidateError" />
                        </div>
                      </div>

                      <div className="col-md-6">
                        <label htmlFor="images">Images</label>
                        <UploadImage onImageSelect={(images) => setProductImages(images)} currentlySelectedImg={productImages} />
                        <ErrorMessage name="productImage" component="div" className="formValidateError" />
                      </div>

                      <div className="col-md-6">
                        <div className="form_group">
                          <label htmlFor="category">Category</label>
                          <Field id="category" name="category" type="text" placeholder="Category" as="select">
                            <option value="">Select Product Category</option>

                            {
                              productCategory.map((catgeoryItem) => {
                                return (
                                  <option value={catgeoryItem._id}>{catgeoryItem.name}</option>
                                )
                              })
                            }
                          </Field>
                          <ErrorMessage name="category" component="div" className="formValidateError" />
                        </div>
                      </div>

                      <div className="col-md-6">
                        <div className="form_group">
                          <label htmlFor="stock">Stock</label>
                          <Field id="stock" name="stock" type="number" placeholder="Stock" />
                          <ErrorMessage name="stock" component="div" className="formValidateError" />
                        </div>
                      </div>


                      <div className="col-md-6">
                        <div className="form_group">
                          <label htmlFor="stock">Options</label>
                          <Field as="select" id="option" name="option">
                            <option value={""}>Select Options</option>
                            {
                              Object.values(const_data.PRODUCT_OPTION).map((options, index) => {
                                return (
                                  <option value={options} key={index}>{options}</option>
                                )
                              })
                            }
                          </Field>
                          <ErrorMessage name="option" component="div" className="formValidateError" />
                        </div>
                      </div>


                      <div className="col-md-6">
                        <div className="form_group">
                          <label htmlFor="status">Status</label>
                          <Field id="status" name="status" as="select">
                            <option value={""}>Select Status</option>
                            <option value={true}>Active</option>
                            <option value={false}>In Active</option>
                          </Field>
                          <ErrorMessage name="status" component="div" className="formValidateError" />
                        </div>
                      </div>



                      <div className="col-12 text-center button_flex">
                        <button className="form_btn btn_green" type="submit">
                          Submit
                        </button>
                        <button className="form_btn btn_warning" type="reset">
                          Cancel
                        </button>
                      </div>
                    </div>
                  </Form>
                </Formik>
              </div>
            </div> : null

          }
        </div>
      </div>
    </AdminLayout>
  )
}

export default EditProduct
