import React, { useEffect, useRef, useState } from 'react'
import AdminLayout from '../../Components/Partials/AdminLayout'
import { ErrorMessage, Field, Formik, Form } from 'formik'
import * as Yup from 'yup'
import { editProduct, getSingleCategory, updateCategoryEndPoint } from '../../api/api_endpoint'
import { useNavigate, useParams } from 'react-router-dom'
import { toast } from 'react-toastify'
import UploadImage from '../../Components/ImageModel/UploadImage'
function EditCategory() {


    const [categoryImage,setCategoryImage] = useState(null)
    let [startEditing, setStartEditing] = useState(true);
    const navigate = useNavigate()

    let [initialValues, setInitialValues] = useState({
        name: "",
        status: false,
        image:""
    });

    let { edit_id } = useParams();


    let validationSchema = Yup.object().shape({
        name: Yup.string("Name should be string").trim().required("Name is required"),
        status: Yup.string("").trim().required("Status is required"),
    })


    function handleSubmit(values) {

        if (startEditing) {
            const imageToUse = categoryImage === initialValues.image ? initialValues.image : categoryImage[categoryImage.length - 1]
            console.log(imageToUse,"Image to");
            
            
            updateCategoryEndPoint(
                edit_id,
                {
                    name: values.name,
                    status: values.status,
                    image: imageToUse,
                }).then((upd) => {
                    let response = upd.data;
                    if (response?.status) {
                        toast.success("Category updated success")
                        navigate("/manage_category")
                    } else {
                        toast.error("Category updated failed")
                    }
                }).catch((err) => {
                    console.log(err)
                    toast.error("Something went wrong")
                })
        }   
    }

    useEffect(() => {
        getSingleCategory(edit_id).then((categoryData) => {
            let res = categoryData.data;
            if (res?.status) {
                let categoryData = res.category;
                setInitialValues({ name: categoryData.name, status: categoryData.status, image : categoryData.image })
                setCategoryImage(categoryData.image)
            }
        }).catch((err) => {

        })
    }, [])



    return (
        <AdminLayout>

            <div className="content_body" id="content_body">
                {startEditing ? <div className="wrapper_content_body">
                    <h2>Edit Category</h2>

                    <div className="content_box_border">
                        <div className="row mt-2">
                            <Formik
                                initialValues={initialValues}
                                validationSchema={validationSchema}
                                onSubmit={handleSubmit}
                                enableReinitialize
                                validate={(values) => {
                                    let error = {};
                                    if (!categoryImage || categoryImage.length === 0) {
                                        error.categoryImage = "Please select valid category images";
                                    } else {
                                        error = {};
                                    }
                                    return error;
                                }}
                            >
                                <Form>
                                    <div className="row">
                                        <div className="col-md-6">
                                            <div className="form_group">
                                                <label htmlFor="name">Name</label>
                                                <Field id="name" name="name" type="text" placeholder="Category Name" />
                                                <ErrorMessage name="name" component="div" className="formValidateError" />
                                            </div>
                                        </div>
                                        <div className="col-md-6">


                                        <div className="form_group">
                                            <label htmlFor="name">Change image</label>
                                            <UploadImage  onImageSelect={(images) => setCategoryImage(images)}/>
                                            <ErrorMessage name="categoryImage" component="div" className="formValidateError" />
                                        </div>
                                        </div>

                                        <div className="col-md-6">
                                            <div className="form_group">
                                                <label htmlFor="name">Status</label>
                                                <Field as="select" name="status" id="status">
                                                    <option value="" label='Select Status' />
                                                    <option value={true} label='Active' />
                                                    <option value={false} label='In Active' />
                                                </Field>
                                                <ErrorMessage name="status" component="div" className="formValidateError" />
                                            </div>
                                        </div>
                                    </div>

                                    <div className="col-12 text-center button_flex">
                                        <button className="form_btn btn_green" type="submit">
                                            Submit
                                        </button>
                                        <button className="form_btn btn_warning" type="reset">
                                            Cancel
                                        </button>
                                    </div>
                                </Form>
                            </Formik>
                        </div>
                    </div>
                </div> : null}
            </div>
        </AdminLayout>
    )
}

export default EditCategory
